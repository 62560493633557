import { contextSelector } from '@ducks/application/context';
import { CONTEXT, build } from '@vsemayki/url-resolver';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

/**
 * @param {Function} fcallbackn - callback function
 * @param {Array}  [dep=[]] - React hook deps
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRedirect = <Fn extends (...args: any[]) => any>(
    callback?: Fn,
    dep: unknown[] = []
) => {
    const router = useRouter();
    const ContextState = useSelector(contextSelector);
    const handleRedirect = useCallback(
        async (value) => {
            if (!value) return;
            if (callback) callback();

            await router.push(
                `/${CONTEXT.catalogue}`,
                build({
                    name: CONTEXT.catalogue,
                    custom: {
                        search_page: value,
                    },
                    filter: ContextState.filter,
                })
            );
        },
        [dep]
    );

    return handleRedirect;
};

export default useRedirect;
