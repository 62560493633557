import * as styles from './styles.scss';
import React, { useRef, useState } from 'react';
import { searchSelector } from '@store/ducks/search';
import Autocomplite from '@uikit/Autocomplite';
import Button from '@uikit/Button';
import SearchIcon from '@src/components/UIKit/Icons/search_inline.svg';
import clsx from 'clsx';
import { UseComboboxStateChangeOptions } from 'downshift';
import { useSelector } from 'react-redux';
import useRedirect from './hooks/useRedirect';
import useGetSuggestions from './hooks/useGetSuggestions';
import cond from 'lodash/cond';
import {
    isAutocompleteBlur,
    isInputChange,
    isClickOrKeyDownEnter,
} from './utils/comboboxActionTypes';
import useControlled from '@src/hooks/useControled';
import useGTMdataLayer from '@src/hooks/useDataLayer';

type DesktopSearchProps = {
    className?: string;
    placeholder?: string;
    collapsed?: boolean;
    collapsedDefault?: boolean;
};
const DesktopSearch: React.FC<DesktopSearchProps> = (props) => {
    const gtm = useGTMdataLayer();
    const { suggestions } = useSelector(searchSelector);
    const [isCollapsed, setIsCollapsed] = useControlled({
        controlled: props.collapsed,
        default: props.collapsedDefault ?? false,
        name: 'isCollapsed',
    });
    const [isEmpty, setIsEmpty] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const isProvidedCollapsed = typeof props.collapsed === 'boolean';
    const suggestionsStrings = suggestions.map((item) => item.text);
    const getSuggestions = useGetSuggestions();
    const handleRedirect = useRedirect();

    const handleClickOrKeyDownEnter = ({
        changes,
    }: UseComboboxStateChangeOptions<string>) => {
        const changedValue =
            changes.selectedItem ?? changes.inputValue?.trim() ?? '';

        handleRedirect(changedValue);

        setIsCollapsed(true);
        setIsEmpty(true);
        getSuggestions('');

        return {
            ...changes,
            selectedItem: null,
            inputValue: '',
        };
    };

    const handleOnBlur = ({
        changes,
    }: UseComboboxStateChangeOptions<string>) => {
        setIsCollapsed(true);
        setIsEmpty(true);
        getSuggestions('');

        return {
            ...changes,
            selectedItem: null,
            inputValue: '',
        };
    };

    const handleInputChange = ({
        changes,
    }: UseComboboxStateChangeOptions<string>) => {
        if (!changes.inputValue) {
            setIsEmpty(true);
            getSuggestions('');
            return changes;
        }

        setIsEmpty(false);
        getSuggestions(changes.inputValue);
        return changes;
    };

    const handleDefault = ({
        changes,
    }: UseComboboxStateChangeOptions<string>) => changes;

    const otherwise = (
        actionAndChanges: UseComboboxStateChangeOptions<string>
    ): boolean =>
        !isClickOrKeyDownEnter(actionAndChanges) &&
        !isInputChange(actionAndChanges) &&
        !isAutocompleteBlur(actionAndChanges);

    const comboboxReducer = cond([
        [isClickOrKeyDownEnter, handleClickOrKeyDownEnter],
        [isAutocompleteBlur, handleOnBlur],
        [isInputChange, handleInputChange],
        [otherwise, handleDefault],
    ]);

    return (
        <div className={clsx(styles.search, props.className)}>
            <Button
                className={clsx(
                    styles.searchButton,
                    !isCollapsed && styles.searchButtonHidden
                )}
                onFocus={() => {
                    if (inputRef.current) {
                        setIsCollapsed(false);
                        inputRef.current.focus();
                    }
                    gtm.current.push({ event: 'click_search_shop' });
                }}
                onClick={() => {
                    if (inputRef.current) {
                        setIsCollapsed(false);
                        inputRef.current.focus();
                    }
                }}
            >
                <SearchIcon className={styles.labelIcon} />
            </Button>
            <Autocomplite
                className={clsx(styles.searchInput, {
                    [styles.searchInputCollapsed]: isProvidedCollapsed
                        ? props.collapsed
                        : isCollapsed,
                })}
                inputProps={{
                    tabIndex: -1,
                    rightIcon: <SearchIcon className={styles.searchIcon} />,
                    placeholder: props.placeholder ?? 'Поиск',
                    onBlur: () => {
                        if (isEmpty) setIsCollapsed(true);
                    },
                    ref: inputRef,
                }}
                comboboxProps={{
                    items: suggestionsStrings,
                    stateReducer: (_state, actionAndChanges) =>
                        comboboxReducer(actionAndChanges),
                    id: 'search',
                    labelId: 'search-label',
                    inputId: 'search-input',
                    menuId: 'search-menu',
                }}
            />
        </div>
    );
};

export default React.memo(DesktopSearch);
