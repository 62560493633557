import React from 'react';
import * as styles from './styles.scss';
import Input, { InputProps } from '@src/components/UIKit/Input';
import CheckIcon from '@uikit/Icons/arrowDown_inline.svg';
import { useCombobox, UseComboboxProps } from 'downshift';
import clsx from 'clsx';

export type AutocompliteProps<Suggestion extends React.ReactNode> = {
    className?: string;
    placeholder?: string;
    inputProps?: InputProps;
    comboboxProps: UseComboboxProps<Suggestion>;
    renderItem?: (item: Suggestion) => React.ReactNode;
    openOnFocus?: boolean;
    showToggleBtn?: boolean;
};
const Autocomplite = <Suggestions extends React.ReactNode>(
    props: AutocompliteProps<Suggestions>
) => {
    const sugguestions = props.comboboxProps.items;
    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
    } = useCombobox(props.comboboxProps);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        props.inputProps?.onFocus && props.inputProps.onFocus(event);
        if (props.openOnFocus && !isOpen) {
            openMenu();
        }
    };

    return (
        <div
            {...getComboboxProps({
                className: clsx(props.className, styles.wrapper),
            })}
        >
            <Input
                className={styles.autocomplite}
                rightIcon={
                    <CheckIcon
                        className={clsx(
                            styles.toggleBtn,
                            props.showToggleBtn && styles.toggleBtnVisible,
                            isOpen && styles.toggleBtnActive
                        )}
                    />
                }
                {...getInputProps({
                    ...props.inputProps,
                    onFocus: handleFocus,
                })}
            />

            <ul
                {...getMenuProps()}
                className={clsx(
                    styles.suggestions,
                    isOpen && styles.suggestionsActive
                )}
            >
                {sugguestions.length > 0 &&
                    sugguestions.map((item, index) => (
                        <li
                            className={clsx(styles.suggestionsItem, {
                                [styles.suggestionsItemHighlighted]:
                                    highlightedIndex === index,
                            })}
                            key={`${item}${index}`}
                            {...getItemProps({ item, index })}
                        >
                            {props.renderItem ? props.renderItem(item) : item}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default Autocomplite;
