import { getSearchItems, searchActions } from '@ducks/search';
import useDebounce from '@src/hooks/useDebounce';
import { useDispatch } from 'react-redux';

/**
 * @param {Array}  [dep=[]] - React hook deps
 */

const useGetSuggestions = () => {
    const dispatch = useDispatch();
    const handleDebounce = useDebounce((query: string) => {
        if (query.length >= 1) {
            dispatch(getSearchItems(query));
        } else {
            dispatch(searchActions.setSuggestions([]));
        }
    });

    return handleDebounce;
};

export default useGetSuggestions;
