import * as React from 'react';

//From https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/useControlled.js

export interface UseControlledProps<T = unknown> {
    /**
     * The default value when uncontrolled.
     */
    default: T;
    /**
     * This prop contains the component value when it's controlled.
     */
    controlled: T | undefined;
    /**
     * The component name displayed in warnings.
     */
    name: string;

    /**
     * The name of the state variable displayed in warnings.
     */
    state?: string;
}

type useControlledFunc = <T = unknown>(
    props: UseControlledProps<T>
) => [T, (newValue: T) => void];

/**
 * @description almost same as useState, but if `controlled` provided then only `controlled` value will return and setState is makes no change of it
 */
const useControlled: useControlledFunc = ({
    controlled,
    default: defaultProp,
    name,
    state = 'value',
}) => {
    const { current: isControlled } = React.useRef(controlled !== undefined);
    const [valueState, setValue] = React.useState(defaultProp);
    const value = controlled ? controlled : valueState;

    if (process.env.NODE_ENV !== 'production') {
        React.useEffect(() => {
            if (isControlled !== (controlled !== undefined)) {
                console.error(
                    [
                        `A component is changing the ${
                            isControlled ? '' : 'un'
                        }controlled ${state} state of ${name} to be ${
                            isControlled ? 'un' : ''
                        }controlled.`,
                        'Elements should not switch from uncontrolled to controlled (or vice versa).',
                        `Decide between using a controlled or uncontrolled ${name} ` +
                            'element for the lifetime of the component.',
                        "The nature of the state is determined during the first render, it's considered controlled if the value is not `undefined`.",
                        'More info: https://fb.me/react-controlled-components',
                    ].join('\n')
                );
            }
        }, [controlled]);

        const { current: defaultValue } = React.useRef(defaultProp);

        React.useEffect(() => {
            if (defaultValue !== defaultProp) {
                console.error(
                    [
                        `A component is changing the default ${state} state of an uncontrolled ${name} after being initialized. ` +
                            `To suppress this warning opt to use a controlled ${name}.`,
                    ].join('\n')
                );
            }
        }, [JSON.stringify(defaultProp)]);
    }

    const setValueIfUncontrolled = React.useCallback((newValue) => {
        if (!isControlled) {
            setValue(newValue);
        }
    }, []);

    return [value, setValueIfUncontrolled];
};
export default useControlled;
