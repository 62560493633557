import { useCombobox, UseComboboxStateChangeOptions } from 'downshift';

export const isClickOrKeyDownEnter = ({
    type,
}: UseComboboxStateChangeOptions<string>) =>
    type === useCombobox.stateChangeTypes.ItemClick ||
    type === useCombobox.stateChangeTypes.InputKeyDownEnter;

export const isAutocompleteBlur = ({
    type,
}: UseComboboxStateChangeOptions<string>) =>
    type === useCombobox.stateChangeTypes.InputBlur;

export const isInputChange = ({
    type,
}: UseComboboxStateChangeOptions<string>) =>
    type === useCombobox.stateChangeTypes.InputChange;
